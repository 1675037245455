import React, { useState,useEffect } from 'react'
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, FormLabel} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { Formik,Form,connect,FieldArray} from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"
import API from "../../../../../util/Api"
import _ from "lodash";
import moment from 'moment'


const ValidationSchema =(type)=>{
    if(type === 'create_client'){
        return Yup.lazy(values=>{
          return Yup.object().shape({
            dialogForm:Yup.array().of(
              Yup.object().shape({
                  client_name:Yup.string().required("Required")
                })
            ).min(1, "Need at least a row")
          });
        });
    } else if(type === 'add_client_user'){
        return Yup.lazy(values=>{
          return Yup.object().shape({
            dialogForm:Yup.array().of(
              Yup.object().shape({
                first_name:Yup.string()
                  .required("Required")
                  .min(2, "Client name must be at least 2 characters long"), // Minimum length validation for client_code
                vehicle_number: Yup.string().required('Required'),
                vehicle_type: Yup.object().required('Required'),
                phone: Yup.string().matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number').required("Required"),
                email: Yup.string().email('Invalid email').required('Required'),
              })
            ).min(1, "Need at least a row")
          });
        });
    } else if(type==='create_client_subscription'){
        return Yup.lazy(values=>{
          return Yup.object().shape({
            start:Yup.string().required("Required"),
            end:Yup.string().required("Required"),
            vehicle_limit: Yup.string().required("Required"),
            amount: Yup.string().required("Required"),
            vehicle_type: Yup.string().required("Required"),
          });
        });
    } 
    else if(type === 'edit_client'){
      return Yup.lazy(values=>{
        return Yup.object().shape({
          client_name:Yup.string()
          .required("Required")
          .min(2, "Client name must be at least 2 characters long"),
          client_code:Yup.string().required("Required"),
          dialogForm:Yup.array().of(
            Yup.object().shape({
              vehicle_number: Yup.string().required('Required'),
              vehicle_type: Yup.string().required('Required')
            })
          ).min(1, "Need at least a row")
        });
      });
    }
     else if(type === 'edit_client_user'){
      return Yup.lazy(values=>{
        return Yup.object().shape({
          first_name:Yup.string().required("Required"),
          email: Yup.string().email('Invalid email').required('Required'),
        });
      });
    }
    else if(type === 'renew_client_subscription'){
      return Yup.lazy(values=>{
        return Yup.object().shape({
          start:Yup.string().required("Required"),
          end:Yup.string().required("Required"),
          amount:Yup.string().required("Required")
        });
      });
    }
}


const DialogFormArr = (props) => {
  console.log("dialogForm", props);
  useEffect(()=>{
    if(props.resetFormik){
        props.formik.resetForm();
        props.setFalse();
    }
  },[props.resetFormik])

  return (
    <Form>
      <DialogContent>
        <FieldArray
          name={"dialogForm"}
          render={({ insert, remove, push }) => {
            return props.formik.values.dialogForm.map((value, i) => {
              return (
                <div className={"row"}>
                  {props.config.map((field, index) => {
                    return (
                      <div
                        className={`col-md-${field.size} col-12`}
                        key={`rowField-${field.key}`}
                      >
                        <FormField
                          field={{
                            ...field,
                            ...{ name: `dialogForm.${i}.${field.key}` },
                          }}
                        />
                      </div>
                    );
                  })}
                  <div className="col-sm-1">
                    {props.formik.values.dialogForm.length > 1 && (
                      <IconButton
                        aria-label="remove"
                        onClick={(e) => {
                          remove(i);
                        }}
                      >
                        <RemoveIcon color="error" />
                      </IconButton>
                    )}
                    {i === props.formik.values.dialogForm.length - 1 && (
                      <IconButton
                        aria-label="add"
                        onClick={(e) =>
                          push(props.newRow) // add config in props
                        }
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={()=>{props.onClose(true, '')}}>
          {"Close"}
        </Button>
        <Button variant="outlined" type="submit" color="primary">
          {"Save"}
        </Button>
      </DialogActions>
    </Form>
  );
};

const DialogFormSingle = (props) => {
  useEffect(()=>{
      if(props.resetFormik){
          props.formik.resetForm();
          props.setFalse();
      }
  },[props.resetFormik])
  const values = _.cloneDeep(props.formik.values);

return (
  <Form>
    <DialogContent>
      <div className={"row"}>
          {props.config.map((field, index) => {
          return (
              <div
              className={`col-md-${field.size} col-12`}
              key={`rowField-${field.key} `}
              >
              <FormField
                field={{
                  ...field,
                  ...{ name: field.key },
                  ...((field.key === 'start' || field.key === 'end') && {
                    minDate: field.key === 'end' ? moment(values.start).add(1, 'days') : field.minDate,
                    maxDate: field.key === 'start' ? moment(values.end).subtract(1, 'days'): new Date('2027-01-01')
                  })
                }}
              />
              </div>
          );
          })}
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="secondary" onClick={()=>{props.onClose(true, '')}}>
        {"Close"}
      </Button>
      <Button variant="outlined" type="submit" color="primary">
        {"Save"}
      </Button>
    </DialogActions>
  </Form>
);
};

const EZFormMultiple = connect(DialogFormArr)

const EZFormSingle = connect(DialogFormSingle)


const DialogFrom = (props)=>{

    const [message,setMessage] = useState('');
    const [resetF,setResetF] = useState(false);
    const submitForm = (e) =>{
      if(props.dialogType === 'create_client' || props.dialogType === 'edit_client'){
        console.log("submitClients",e)
        API.post('valetClients',JSON.stringify(e)).then(response=>{
            console.log("create_update_client",response.data)
            setMessage(response.data.msg)
            setResetF(true)
            setTimeout(()=>{
                props.onClose(true,'loc_clients');
            },200)

        }).catch(error=>{
            setMessage(error.response.data.msg);
            console.log(error)
        })
      } else if (props.dialogType === 'add_client_user' || props.dialogType === 'edit_client_user'){
        API.post('valetClientUsers',JSON.stringify(e)).then(response=>{
          console.log("client_user",response.data)
          setMessage(response.data.msg)
          setResetF(true)
          setTimeout(()=>{
              props.onClose(true,'client_users');
          },200)
        }).catch(error=>{
            setMessage('Failed to save')
            console.log(error)
        })
      } else if(props.dialogType === 'create_client_subscription'){
        API.post('valetClientSubscription',JSON.stringify(e)).then(response=>{
          console.log("valet_subscription",response.data)
          setMessage(response.data.msg)
          setResetF(true)
          setTimeout(()=>{
              props.onClose(true, 'subscriptions');
          },200)
        }).catch(error=>{
            setMessage(error.response.data.msg)
            console.log(error)
        })
      } else if(props.dialogType === 'renew_client_subscription'){
        API.post('valetClientSubscriptionRenew',JSON.stringify(e)).then(response=>{
          console.log("renew_subscription",response.data)
          setMessage(response.data.msg)
          setResetF(true)
          setTimeout(()=>{
              props.onClose(true,'subscriptions');
          },200)
        }).catch(error=>{
            setMessage(error.response.data.msg)
            console.log(error)
        })
      }
    }
    return(
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={(props.dialogType === ' ' || props.dialogType === 'create_client') ? 'sm' : 'lg'}
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <Typography color='primary' variant='h6' style={{marginLeft:30}} >{message}</Typography>
            <Formik
                onSubmit={submitForm}
                initialValues={props.initialValues}
                validationSchema={ValidationSchema(props.dialogType)}
                resetInitalValue={true}
            >
              {
                (props.dialogType === 'create_client' || props.dialogType === 'add_client_user') ? 
                  <EZFormMultiple onClose={props.onClose} newRow={props.initialValues?.dialogForm[0]} config={props.config} location={props.location} resetFormik={resetF} setFalse={()=>{console.log('setResetF');setResetF(false)}} />
                : <EZFormSingle onClose={props.onClose} config={props.config} location={props.location} resetFormik={resetF} setFalse={()=>{console.log('setResetF');setResetF(false)}} />

              }
            </Formik>
      </Dialog>
    )
}
export default DialogFrom