import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, connect, FieldArray } from "formik";
import { Button, Box, Typography, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import SnackBar from "components/SnackBar";
import _ from "lodash";
import ContainerHeader from "components/ContainerHeader";
import * as Yup from "yup";
import API from "../../../../../util/Api";
import dummyData from "./dummyConfig";
import { makeStyles } from "@material-ui/core/styles";
import ToolTipIcon from "components/ToolTipIcon";
import RemoveIcon from '@material-ui/icons/Remove'
import PlusOneIcon from '@material-ui/icons/PlusOne';

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: "10px 0",
  },
  title: {
    marginBottom: 10,
  },
  field: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const ConfigArray = connect((props) => {
  const classes = useStyles();
  const emptyField = props.section?.fields?.reduce((obj, field) => {
    obj[field.key] = field.value;
    return obj;
  }, {});

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <Typography className={classes.title} varient={"h5"} component={"h5"}>
            {props.section.title}
          </Typography>
        </div>
        <div className="col-12">
          <FieldArray
            name={props.section.key}
            render={arrayHelpers => {
              return (
                props.formik.values[props.section.key]?.length ?
                  props.formik.values[props.section.key].map((value, index) => {
                    return (
                      <div className="row">
                        {props.section.fields.map((field) => (
                          (!field.dependency || field.dependencyValue == props.formik.values[props.section.key][field.dependency]) &&
                          <div className="col-md-2 col-12" key={`field-${field.key}`}>
                            <FormField
                              field={{
                                ...field,
                                ...{ name: `${props.section.key}.${index}.${field.key}` },
                              }}
                            />
                          </div>
                        ))}
                        <IconButton aria-label="remove" onClick={(e) => {
                          console.log('remove index', index);
                          arrayHelpers.remove(index)
                        }}>
                          <RemoveIcon color="error" />
                        </IconButton>
                        {index == props.formik.values[props.section.key].length - 1 && !props.section.limit &&
                          <IconButton aria-label="add" onClick={(e) => arrayHelpers.push(emptyField)}>
                            <AddIcon color="primary" />
                          </IconButton>}
                      </div>)
                  })
                  : <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    startIcon={<PlusOneIcon />}
                    onClick={(e) => arrayHelpers.push(emptyField)}
                  >
                    {`Add`}
                  </Button>
              )
            }}>
          </FieldArray>
        </div>
      </div>
    </>
  )
})

const ConfigSection = connect((props) => {
  const classes = useStyles();
  console.log("config-section-props", props);

  return (
    <div className="row mb-5">
      <div className="col-12">
        <Typography className={classes.title} varient={"h5"} component={"h5"}>
          {props.section.title}
        </Typography>
      </div>
      <FieldArray name={props.section.key}>
        <>
          {props.section.fields.map((field) => (
            (!field.dependency || field.dependencyValue == props.formik.values[props.section.key][field.dependency]) &&
            <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField
                field={{
                  ...field,
                  ...{ name: `${props.section.key}.${field.key}` },
                }}
              />
            </div>
          ))}
        </>
      </FieldArray>
    </div>
  );
});

const ConfigDetails = connect((props) => {
  console.log("config-details-props", props);
  useEffect(() => {
    if (Object.keys(props.formik.errors).length > 0) {
      let values = props.formik.values;
      const errors = props.formik.errors;
      for (let sectionKey in errors) {
        for (let fieldKey in errors[sectionKey]) {
          if (errors[sectionKey][fieldKey] === "Required" && !values[sectionKey][fieldKey]) {
            values[sectionKey][fieldKey] = "";
          }
        }
      }
      props.formik.setValues(values);
    }
  }, [props.formik.errors]);

  return (
    <div className="row">
      <CardBox styleName="col-12" heading="">
        <Form>
          {props.config.sections.map((section, index) => (
            section.fields?.length > 0 && (section.multi ? <ConfigArray section={section} key={`sec-${index}`} />
              : <ConfigSection section={section} key={`sec-${index}`} />)
          ))}
          {/* BUTTONS */}
          <div className="row mt-2">
            <div className="col-lg-6 col-sm-12 col-12">
              <Box mt="20px">
                <Box mr="10px" display="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    mr="5px"
                    type="submit"
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button variant="outlined" color="secondary" type="reset">
                    Reset
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Form>
      </CardBox>
    </div>
  );
});

const ControllerConfig = (props) => {
  console.log("controller-config-props", props);
  const [initialValues, setInitialValues] = useState();
  const [config, setConfig] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const { controllerId, name, type } = useParams();

  const validations = Yup.lazy(values => {
    return Yup.object().shape({
      rfidConfig: Yup.object()
        .default(null)
        .nullable()
        .shape({
          // type: Yup.string().required("Required"),
          // ip_address: Yup.string().required("Required"),
          // port: Yup.string().required("Required"),
          read_interval: Yup.string().required("Required"),
          // reader_type: Yup.string().required("Required"),
          // baudrate: Yup.string().required("Required"),
          reader_mode: Yup.string().required("Required"),
          // vehicle_type: Yup.array().required("Required"),
          controller_mode: Yup.string().required("Required"),
          parking_guard_id: Yup.object().required("Required"),
        }),
      nfcConfig: Yup.object()
        .default(null)
        .nullable()
        .shape({
          ble_name: Yup.string().max(10, "Too long"),
          // parking_guard_id: Yup.object().required("Required"),
        }),
      loopConfig: Yup.object()
        .default(null)
        .nullable()
        .shape({
          // ip: Yup.string().required("Required"),
          // port: Yup.string().required("Required"),
          parking_guard_id: Yup.object().required("Required"),
        }),
      // ledConfig: Yup.object()
      //   .default(null)
      //   .nullable()
      //   .shape({
      //     led_id: Yup.object().required("Required"),
      //   }),
      // camera: Yup.object()
      //   .default(null)
      //   .nullable()
      //   .shape({
      //     name: Yup.string().required("Required"),
      //     ip: Yup.string().required("Required"),
      //     mode: Yup.string().required("Required"),
      //   }),
      slotSensorAccess: Yup.object()
        .default(null)
        .nullable()
        .shape({
          slot_id: Yup.string().required("Required"),
          mesh_channel: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          router_ssid: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          router_password: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          max_connections: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          max_layer: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          mesh_id: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          mesh_type: values.slotSensorAccess?.mesh ? Yup.string().required("Required") : undefined,
          qsize: values.slotSensorAccess?.mesh ? Yup.number().min(16, "Min 16 Required").required("Required") : undefined,
        }),
    })
  });

  const saveConfig = (e, { setSubmitting }) => {
    console.log("submit-data", e);
    setSubmitting(true);
    API.post("controllerSetting", JSON.stringify({ ...e, controller_id: controllerId }))
      .then((res) => {
        setSubmitting(false);
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
      })
      .catch((err) => {
        try {
          setSnackBar({
            open: true,
            msg: err.response.data.msg,
            type: "error",
          });
        } catch {
          setSnackBar({
            open: true,
            msg: "Something went wrong",
            type: "error",
          });
        }
        setSubmitting(false);
        console.log("catch", err);
      });
  };
  useEffect(() => {
    API.get("controllerSetting", { params: { controller_id: controllerId } })
      .then((res) => {
        // res.data = dummyData;
        console.log("CoinntrollerGetSetting", res.data.data);
        let data = {};
        res.data.data.sections.forEach((section) => {
          if (section.required) {
            data[section.key] = section.values;
          }
        });
        console.log("values", data);
        setInitialValues(data);
        setConfig(res.data.data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, []);

  return (
    <>
      <ContainerHeader
        title={`${type} - ${name}`}
        match={props.match}

      />
      {config && initialValues && (
        <Formik
          onSubmit={saveConfig}
          initialValues={initialValues}
          validationSchema={validations}
        >
          <ConfigDetails config={config} />
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default ControllerConfig;
