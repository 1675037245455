import CardBox from "components/CardBox"
import ContainerHeader from "components/ContainerHeader"
import ReportFilter from "components/ReportTable/ReportFilter"
import moment from "moment"
import { useEffect, useState } from "react"
import API from "util/Api";
import OverviewDetails from "./OveriewDetails"
import _ from "lodash"
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

const ParkingOverView = (props)=>{
    
    const [locations,setLocations] = useState([])
    const [data,setData] = useState(undefined)
    const reportFlags = _.find(props?.reportFlags, { name: 'parking-overview' })?.flags
    const [locationId,setLocationId] = useState(0)
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        API.get("location", {params:{ loc_type: "parking" }}).then((res) => {
            setLocations(res.data.data)
        })
    },[])

    const getParkingOverview = (params, cb)=>{
        params.type = 'parking-overview';
        setLoading(true)
        Object.keys(params).map(function (item, i) {
          if (item === "from" || item === "to")
            params[item] = new Date(params[item]).toISOString();
        });
        console.log("getParkingOverview-",params)
        setData(null)
        setLocationId(params.location)
        API.get("parking_overview?data=" + JSON.stringify(params)).then((res) => {
            setData(res.data.data)
            setLoading(false)
            cb()
        }).catch(err=>{
            cb()
            setLoading(false)
        })
    }

    return(
        <div>
            <ContainerHeader title={"Overview"} match={props.match} />
            {
                reportFlags &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <ReportFilter
                            locations={locations}
                            reportType={['overview']}
                            requestStatus={{ msg: "", success: true }}
                            getReportData={getParkingOverview}
                            flags={reportFlags}
                            vendors={[]}
                            minFromDate={moment(Date.now()).subtract(39, "days")} 
                            maxToDate={moment(Date.now()).add(1, "days")}
                        />
                    </CardBox>
                    {
                        loading &&
                        <div className="w-100">
                            <LinearProgress color="primary" /><LinearProgress color="primary" />
                        </div>
                    }
                    
                    {
                        data &&
                            <OverviewDetails locationId={locationId} data={data} ></OverviewDetails>
                    }
                </div>
            }
            
        </div>
    )
}
const mapStateToProps = function (state) {
  try {
    return {
      reportFlags: _.concat(
        state.auth.authUser.reports,
        state.auth.authUser.subsc,
        state.auth.authUser.ezcharge,
        state.auth.authUser.valet,
        state.auth.authUser.access
      ),
      dashboardActions: state.auth.authUser.dashboardactions,
    };
  } catch (err) { }
};
export default connect(mapStateToProps) (ParkingOverView)