import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import motorCycleImage from '../../assets/images/motorCycle.png'; 
import carETA from '../../assets/images/carETA.png'; 

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    LabelList
  } from "recharts";


export const EZDoughnut = (props)=>{
    const image = new Image();
    image.src = props.vehicleType == 'bike' ? motorCycleImage : carETA;
    
    const plugin = {
      id: 'customCanvasBackgroundImage',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const {top, left, width, height} = chart.chartArea;
          const imageWidth = 55; // Set the width you want
          const imageHeight = 55; 
          const x = left + width / 2 - imageWidth / 2;
          const y = top + height / 2 - imageHeight / 2;
          ctx.drawImage(image, x, y,imageWidth,imageHeight);
        } else {
          image.onload = () => chart.draw();
        }
      }
    }; 
    const options =  {
        cutout: '75%',
    }
    // Chart configuration
  const config = {
    type: 'doughnut',
    data: props.data,
    plugins: [plugin],
  };
    if(props.legend){
        ChartJS.register(ArcElement, Tooltip,Legend);

    }else{
        ChartJS.register(ArcElement, Tooltip);
    }
    return(<Doughnut  data={props.data} options={options}  plugins={config.plugins}  style={props?.style} />)
}

export const BarChartt = (props)=>{
    const transformedData = [
        {
            name: 'Vehicles',
            bike: props.data.datasets[0].data[0],  
            car: props.data.datasets[0].data[1],  
            bikeColor: props.data.datasets[0].backgroundColor[0],
            carColor:  props.data.datasets[0].backgroundColor[1] 
        }
    ];
    return (
        <ResponsiveContainer>
            <BarChart 
                style={props?.style}
                data={transformedData}
                layout="vertical" 
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <XAxis type="number" hide />
                <YAxis type="category" hide />
                 {/* Bar for "bike" with labels */}
                 <Bar dataKey="bike" stackId="a" fill={transformedData[0].bikeColor}>
                    <LabelList dataKey="bike" position="inside" fill="white" />
                </Bar>

                {/* Bar for "car" with labels */}
                <Bar dataKey="car" stackId="a" fill={transformedData[0].carColor}>
                    <LabelList dataKey="car" position="inside" fill="white" />
                </Bar>
            </BarChart>
     </ResponsiveContainer>
    )
}