import React, { useEffect, useState, useRef } from "react";
import API from "util/Api";
import Typography from '@material-ui/core/Typography'
import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import EZForm from "components/EZForm";
import * as Yup from "yup";
import { Formik } from "formik";
import _ from "lodash";
import SnackBar from "components/SnackBar";

const ParkingSlots = (props) => {
    const canvasRef = useRef();
    const [fields, setFields] = useState();
    const [floor, setFloor] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const validations = Yup.object().shape({
        location: Yup.object().required("Required"),
    });

    const handleSubmit = (data, form) => {
        setFloor(undefined);
        API.post("parkingFloorMap", JSON.stringify(data))
            .then((res) => {
                setFloor(res.data.data);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleChange = (formik) => {
        console.log('handleChange - ', formik)
        const values = formik.values;
        if (values.location && !_.isEqual(values.location.floors, fields.floor.options)) {
            formik.setFieldValue('floor_id', '');
            const config = _.cloneDeep(fields);
            config.floor.options = values.location.floors;
            setFields(config);
        }
    }

    useEffect(() => {
        API.get("parkingFloorMap")
            .then((res) => {
                setFields(res.data.data.fields);
            })
            .catch((err) => {
                console.error("error", err);
            });
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas && floor) {
            const context = canvas.getContext('2d');
            const image = new Image();

            image.src = floor.floor_map_url;

            image.onload = () => {
                // Clear the canvas
                // context.clearRect(0, 0, canvas.width, canvas.height);
                context.reset();

                // Set canvas size to match image size
                canvas.width = image.width;
                canvas.height = image.height;

                // Draw the image on the canvas
                context.drawImage(image, 0, 0);
                for (const row of floor.slots) {
                    if (row.slot_coordinates) {
                        const slot = JSON.parse(row.slot_coordinates);
                        context.globalAlpha = 0.5;
                        context.fillStyle = row.is_available == 1 ? '#81C784' : "#EF5350";
                        context.fillRect(slot.x, slot.y, slot.w, slot.h);
                    }
                }
            };
        }
    }, [floor])

    return (
        <>
            <ContainerHeader title={`Parking Slots`} match={props.match} />
            {fields && <EZForm
                fields={Object.values(fields)}
                validationSchema={validations}
                handleChange={handleChange}
                onSubmit={handleSubmit}
            />}
            <div className="row" style={{ display: floor ? 'block' : 'none' }}>
                <CardBox styleName="col-12">
                    <canvas
                        ref={canvasRef}
                        style={{ width: '100%' }}
                    />
                </CardBox>
            </div>
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )
}
export default ParkingSlots;