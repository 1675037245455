import React from 'react'
import API from 'util/Api'
import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import CardBox from 'components/CardBox'
import MsgPopover from 'components/MsgPopover'
import { SwapHoriz } from '@material-ui/icons'
import { Formik } from 'formik'
import { AddFreeDetails } from './AddFreeDetails'
import _ from 'lodash'
import * as Yup from "yup"
import SnackBar from 'components/SnackBar'

const SignupSchema = () => {
    return Yup.lazy(values => {
        return Yup.object().shape({
            location_id: Yup.object().
                required('Required'),
            vehicle_no: Yup.string()
                .min(4, 'Too Short!')
                .required('Required'),
            free_id: Yup.string()
                .required('Required'),
            phone_no: values.vehicle_no?.length <= 4 ?
                Yup.string().required('Required') : undefined
        });
    });
}
const getFieldValues = (fields) => {
    const values = Object.values(fields).reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

class AddFreeParking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            freeParkingList: [],
            freeParkingListFilter: [],
            appState: 'waiting',
            initialValues: {},
            initialConfig: [],
            resetFormik: false,
            snackOpen: false,
            snackMsg: '',
            snackType: 'error',
            loading: false
        }
    }
    componentDidMount() {
        API.get("freeParking").then(response => {
            this.setState({
                initialValues: getFieldValues(response.data.data.config),
                initialConfig: response.data.data.config,
                freeParkingList: this.formatList(response.data.data),
                appState: 'active'
            })
        }).catch(err => {
            console.log(err)
        })
    }
    formatList = (data) => {
        return [
            {
                title: 'Pending Free Parking List',
                table: data.freeParkingListPending
            },
            {
                title: 'Completed Free Parking List',
                table: data.freeParkingListCompleted
            }
        ]
    }
    submitFreePark = (e, { setSubmitting }) => {
        console.log("submitFreePark", e)
        let self = this
        self.setState({ loading: true })
        setSubmitting(true)
        API.post('addFreeParking', JSON.stringify(e)).then((res) => {
            console.log("addFreeParkingPost", res)
            self.setState({
                freeParkingList: this.formatList(res.data.data),
                loading: false
            })
            self.setState({ snackOpen: true, snackMsg: 'Successfully added', snackType: 'success' })
            setSubmitting(false)
            this.resetFreePark()
        })
            .catch((err) => {

                try {
                    if (err.response.status === 401) {
                        self.setState({ snackOpen: true, snackMsg: err.response.data.msg, snackType: 'error', loading: false })
                    } else {
                        self.setState({ snackOpen: true, snackMsg: 'Something went wrong', snackType: 'error', loading: false })
                    }
                } catch {
                    self.setState({ snackOpen: true, snackMsg: 'Something went wrong', snackType: 'error', loading: false })
                }
                setSubmitting(false)
            })
    }
    resetFreePark = () => {
        this.setState({ resetFormik: true });
    }
    renderState(state) {
        switch (state) {
            case "loading":
            default:
                return (<div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><CircularProgress color="secondary" size={50} /></div>)
            case "active":
                return (<div> {this.renderPage()} </div>)
        }
    }
    renderPage() {
        console.log("renderPage", this.state)
        return (
            <div>
                <div>
                    <div className="row">
                        <CardBox styleName="col-12" heading={"Add Free Parking"}>
                            <Formik
                                onSubmit={this.submitFreePark.bind(this)}
                                onReset={this.resetFreePark.bind(this)}
                                initialValues={this.state.initialValues}
                                enableReinitialize={true}
                                validationSchema={SignupSchema}
                            >
                                <AddFreeDetails
                                    refresh={this.state.resetFormik}
                                    resetRefresh={() => { this.setState({ resetFormik: false }) }}
                                    initialConfig={this.state.initialConfig}
                                    updateConfig={(config) => { this.setState({ initialConfig: config }) }} />
                            </Formik>
                        </CardBox>
                        {
                            this.state.loading &&
                            <div className={'col-12'} style={{ margin: '10px 0px' }}><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
                        }
                        {
                            this.state.freeParkingList?.map(item => (
                                <CardBox headerOutside styleName="col-12" cardStyle="p-0"
                                    heading={
                                        <React.Fragment>
                                            {item.title}
                                            <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                                                <Box display={{ xs: 'inline', md: 'none' }}><SwapHoriz color="primary" /></Box></MsgPopover>
                                        </React.Fragment>
                                    }>
                                    <SimpleTable type={'freeParking'} table={item.table} />
                                </CardBox>
                            ))
                        }

                    </div>
                </div>

            </div>
        );
    }
    render() {
        return (
            <div>
                {this.renderState(this.state.appState)}
                <SnackBar
                    open={this.state.snackOpen}
                    handleClose={() => { this.setState({ snackOpen: false, snackMsg: "" }) }}
                    message={this.state.snackMsg}
                    type={this.state.snackType}
                />
            </div>)
    }
}

export default AddFreeParking;