import React, { useEffect, useState } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box,Button } from '@material-ui/core'
import _ from 'lodash';
import moment from 'moment'
import RenewFilter from './RenewFilter'
import ReportTable from 'components/ReportTable/index';
import PopUpDialog from 'components/PopUpDialog';
import { saveAs } from "file-saver";
import ActionsTable from 'components/ActionsTable/TableList';
import SnackBar from "components/SnackBar";
import DialoagForm from './dialogForm';

const ClientSubscription =(props)=> {

   const [pageState, setPageState] = useState({
            match: props.match,
            appState:'loading',
            errorMessage: '',
            isShowing:false,
            isBtnDisabled:false,
        });

    const [ subscriptionTable, setSubscriptionTable ] = useState({});
    const [ clientAndUserTable, setClientAndUserTable ] = useState({});
    const [ dialogFormConfig, setDialogFormConfig ] = useState({});
    const [ locations, setLocations ] = useState({});
    const [ clients, setClients ] = useState({});
    const [ selectedLoc, setSelectedLoc ] = useState({id:0, name:''});
    const [ selectedClient, setSelectedClient ] = useState({id:0, name:''});
    const [ dialogFormProps, setDialogFormProps ] =useState({ title:'', type:'', isShowing:false, isEditShowing:false });
    const [ dialogConfig, setDialogConfig ] =useState([]);
    const [ dialog, setDialog ] = useState({ open:false, type:'', header:'', text: '', agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel', rowItem: {id:0, active:true}, callback:null });
    const [ snackBar, setSnackBar ] = useState({ open: false, msg: "", type: "error" });
    const [ initialValues, setInitialValues ] = useState({});
    
    useEffect(()=>{
        getValetLocs();
    },[])

    const getValetLocs = () =>{
        API.get('valetLocationAndClient')
        .then((res) => {
            // received data
            console.log('rcvd data',res.data.data)
            setLocations(res.data.data.locations)
            setClients(res.data.data.clients)
            setDialogFormConfig(res.data.data.dialogFormConfig);
            setPageState(currState =>({...currState, appState:'active', errorMessage: ""}));
        })
        .catch((err) => {
            // got an error
            setPageState(currState =>({...currState, errorMessage: "Please try again"}));
        })
    }

    const getData = (params) => {
        let location = params.location;
        let client = params.client;
        setPageState(currState =>({...currState, appState:'waiting'}));
        API.get(`valetSubscriptions?location=${location}&client=${client}`).then((res)=> {
            setSubscriptionTable(res.data.data);
            API.get(`valetClientsAndClientUsers?location=${location}&client=${client}`).then((res) => {
                // console.log("getData__ Client & User", res.data.data);
                setPageState(currState =>({...currState, appState:'active', errorMessage: ""}));
                setClientAndUserTable(res.data.data);
            })
            .catch((err)=> {
                setPageState(currState =>({...currState, errorMessage: "Please Try Again"}));
            })
        }).catch((err) => {
            // got an error
            setPageState(currState =>({...currState, errorMessage: "Please try again"}));
        })
    }

    const renewSubscription = (params) => {

        console.log("renew_subscription", params)
        let subscription = _.cloneDeep(params);
        const oneMonthAgo = moment(moment().subtract(1, 'M'), 'DD-MM-YYYY');
        const endDate = moment(params.end, 'DD-MM-YYYY');
        if (endDate.isBefore(oneMonthAgo)) {
            const dateObj = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            subscription.start = moment(dateObj,'DD-MM-YYYY').toDate();
            subscription.end = moment(dateObj,'DD-MM-YYYY').add(1,'M').subtract(1, 'day').toDate();
            subscription.minStart = moment(dateObj,'DD-MM-YYYY').subtract(1,'M').toDate();
        } else {
            subscription.start = moment(params.end,'DD-MM-YYYY').toDate();
            subscription.end = moment(params.end,'DD-MM-YYYY').add(1,'M').toDate();
            subscription.minStart = moment(params.end,'DD-MM-YYYY').subtract(1,'M').toDate();
        }
        setDialogConfig([
            ...dialogFormConfig.renewClientSubscription.fields.map((field) => {
            if (field.key === 'start') {
                return {
                    ...field,
                    "minDate": subscription.minStart
                };
            }
            return field;
        })]);
        setInitialValues({ vcs_id: params.vcs_id_hidden, start:subscription.start, end:subscription.end, amount:''});
        setDialogFormProps({...dialogFormProps, title:'Renew Client Subscription', type:'renew_client_subscription', isShowing:true});
    }
   
    const handleConfirmDialogClose = () => { 
        setDialog({ open:false, header:'', text: '', agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel', type:'', rowItem: {id:0, active:true}, callback:null});
        // isBtnDisabled:false  //check_this
    }
    
    const handlePopupConfirmAction = () => {
        console.log("handlePopupConfirmAction", dialog)
        const type = dialog.type;
        if(type === 'clientStatus'){
            setPageState(currState =>({...currState, isBtnDisabled:true}));
            API.put("valetClientStatus", JSON.stringify(dialog.rowItem))
                .then((res)=> {
                    handleConfirmDialogClose();
                    dialog.callback(dialog.rowItem.active)
                })
                .catch((err)=>{
                    handleConfirmDialogClose();
                    console.log("post config error ", err)
                })
        } else if(type === 'clientUserStatus'){
            setPageState(currState =>({...currState, isBtnDisabled:true}));
            API.put("valetClientUserStatus", JSON.stringify(dialog.rowItem))
                .then((res)=> {
                    handleConfirmDialogClose();
                    dialog.callback(dialog.rowItem.active)
                })
                .catch((err)=>{
                    handleConfirmDialogClose();
                    console.log("post config error ", err)
                })
        } else if(type === 'clientSubscriptionStatus'){
            setPageState(currState =>({...currState, isBtnDisabled:true}));
            API.put("valetClientSubscriptionStatus", JSON.stringify(dialog.rowItem))
                .then((res)=> {
                    handleConfirmDialogClose();
                    getData({location:selectedLoc.id, client: selectedClient.id});
                })
                .catch((err)=>{
                    handleConfirmDialogClose();
                    console.log("post config error ", err)
                })
        }
    }

    const handleClientEdit = (client) => {
        console.log("edit ",client, pageState)
        setDialogConfig(dialogFormConfig.valetClient.fields);
        setInitialValues({ id: client.id.value, client_name: client.name.value, address:client.location.value === '-' ? '' : client.location.value, client_code: client.client_code.value});
        setDialogFormProps({ ...dialogFormProps, title:'Edit Client', type:'edit_client', isShowing:true });
    }

    const handleUserEdit = (user) => {
        console.log("handleUserEdit", user);
        setDialogConfig(dialogFormConfig.editValetClientUser.fields);
        setInitialValues({ id: user.id.value, first_name: user.fname.value, last_name: user.lname.value, phone: (user.phone.value == '-')? '' : user.phone.value, email: user.email.value});
        setDialogFormProps({ ...dialogFormProps, title:'Edit Client User', type:'edit_client_user', isShowing:true });
    }

    const handleClientStatus = (id, checked, cb) => {
        const client = _.find(clientAndUserTable?.clients.body, (client) => client.id.value === id);
        const heading = `${checked ? 'Activate' : 'Dectivate'} client`;
        const text = `Are you sure you want to ${checked ? 'activate' : 'deactivate'} ${client.name.value}?`;
       
        setDialog({
            open: true, type:'clientStatus', heading, text, agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel',
            rowItem: { id: client.id.value, active: checked },
            callback:cb
        });
    }
    
    const handleUserStatus = (id, checked, cb) => {
        const user = _.find(clientAndUserTable?.users.body, (user) => user.id.value === id);
        const heading = `${checked ? 'Activate' : 'Dectivate'} client`;
        const text = `Are you sure you want to ${checked ? 'activate' : 'deactivate'} ${user.name.value}?`;
        setDialog({
            open: true, type:'clientUserStatus', heading, text, agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel',
            rowItem: { id: user.id.value, active: checked },
            callback:cb
        });
    }
    
    const onEnable = (params,checked) => {
        var text = checked ? 'Are you sure you want to activate subscription ?' : 'Are you sure you want to deactivate subscription?'
        var heading = checked ? 'Activate Subscription' : 'Deactivate Subscription'
        setDialog({
            open: true, type:'clientSubscriptionStatus', heading, text, agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel',
            rowItem: { id: params.vcs_id_hidden, active: checked }, callback:null
        });
    }
    
    const openDialogForm = (type) => {
        if(type === 'create_client'){
            if(selectedLoc.id === 0){
                setSnackBar({ open: true, msg: "Please select location", type: "warning" });
            } else{
                setDialogConfig(dialogFormConfig.valetClient.fields)
                setInitialValues({ dialogForm: [{ location_id:selectedLoc.id, client_name:'', location:selectedLoc.name }] });
                setDialogFormProps({ ...dialogFormProps, title:'Create Client', type, isShowing:true });
            }
        } else if(type === 'add_client_user'){
            if(selectedClient.id === 0){
                setSnackBar({ open: true, msg: `Please select ${selectedLoc.id === 0 ? 'location and client.' : 'client.'}`, type: "warning" });
            } else{
                setDialogConfig([
                    ...dialogFormConfig.valetClientUser.fields.map((field) => {
                        if (field.key === 'vehicle_type') {
                            return {
                                ...field,
                                "options": _.find(locations,{id:selectedLoc.id}).vehicle_type
                            };
                        }
                        return field;
                    })]
                );
                setInitialValues({ dialogForm: [{location_id:selectedLoc.id, client_id:selectedClient.id, first_name:'', last_name:'', vehicle_number:'', vehicle_type:'', phone:'', email:'' }] });
                setDialogFormProps({ ...dialogFormProps, title:'Add Client User', type, isShowing:true });
            }
        } else if(type === 'create_client_subscription'){
            if(selectedClient.id === 0){
                setSnackBar({ open: true, msg: `Please select ${selectedLoc.id === 0 ? 'location and client.' : 'client.'}`, type: "warning" });
            } else{
                setDialogConfig([
                    ...dialogFormConfig.valetClientSubscription.fields.map((field) => {
                        if (field.key === 'vehicle_type') {
                            return {
                                ...field,
                                "options":_.find(locations,{id:selectedLoc.id}).vehicle_type ,
                            };
                        }
                        return field;
                    })
                ]);
                const dateObj = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                setInitialValues({ start:moment(dateObj,'DD-MM-YYYY').toDate(), end:moment(dateObj,'DD-MM-YYYY').add(1,'M').subtract(1, 'day').toDate(), amount:'', client_id:selectedClient.id, location_id:selectedLoc.id, vehicle_limit:'', vehicle_type: '' });
                setDialogFormProps({ ...dialogFormProps, title:'Create Client Subscription', type, isShowing:true });
            }
        }
    }
    
    return(
        <>{ pageState.appState === 'loading' ?
            <div  style={{
                position: "absolute", 
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent:"center"
            }}>
                <CircularProgress color="secondary" size={50}/>
            </div>
        :
        <div>
            <ContainerHeader title="Renew Subscriptions" match={pageState.match} />    
            <div className="row">
                <CardBox styleName="col-12">
                    <RenewFilter 
                        locations={locations} 
                        clients = {clients}
                        getReportData={getData}
                        setSelectedLoc={setSelectedLoc}
                        setSelectedClient={setSelectedClient}
                    />
                </CardBox>
            </div>
            { pageState.errorMessage!== '' && <h3 className="row"> { pageState.errorMessage } </h3> }
                {pageState.appState==='waiting' 
                ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                : <>
                    { <div style={{display:"flex"}}>
                        { locations && locations.length > 0 &&
                        <Box mr="15px" mb="25px"><Button variant="contained" color="primary" 
                                onClick={()=>{ openDialogForm('create_client'); }}
                                disabled={selectedClient.id === 0 ? false : true}
                            > Create New Client </Button>
                        </Box>}
                        {locations && locations.filter(location => location.id === selectedLoc.id)[0]?.clients?.length > 0 &&
                        <Box mr="15px" mb="25px"><Button variant="contained" color="primary" 
                            onClick={()=>{ openDialogForm('add_client_user'); }}
                            > Add Clients User </Button>
                        </Box>}
                        {locations && locations.filter(location => location.id === selectedLoc.id)[0]?.clients?.length > 0 &&
                        <Box mr="15px" mb="25px"><Button variant="contained" color="primary" 
                            onClick={()=>{ openDialogForm('create_client_subscription'); }}
                            > Create Client Subscription</Button>
                        </Box>}
                   </div> }
                    { Object.keys(subscriptionTable).length > 0 &&
                        <ReportTable downloadCSVBtn onEnable={onEnable} data={subscriptionTable} renewSubscription={renewSubscription} />
                    }
                    { Object.keys(clientAndUserTable)?.length > 0 && Object.keys(clientAndUserTable).includes('clients') && 
                        <ActionsTable 
                            tableHeading={clientAndUserTable?.clients?.title}
                            table={clientAndUserTable?.clients}
                            onEdit = {handleClientEdit}
                            updateStatus={handleClientStatus}
                            actions={true} 
                        />
                    }
                    { Object.keys(clientAndUserTable)?.length > 0 && 
                        <ActionsTable 
                            tableHeading={clientAndUserTable?.users?.title}
                            table={clientAndUserTable?.users} 
                            onEdit = {handleUserEdit}
                            updateStatus={handleUserStatus}
                            actions={true} 
                        />
                    }
                </>
                }

                <PopUpDialog 
                    open={dialog.open}
                    header={dialog.heading}
                    content ={dialog.text}
                    // variant={dialog.headingVariant}
                    agreeBtnTxt ={dialog.agreeBtnTxt}
                    isBtnDisabled={dialog?.isBtnDisabled}
                    disAgreeBtnTxt={dialog.disAgreeBtnTxt}
                    agreeClicked ={handlePopupConfirmAction}
                    handleDialogClose={handleConfirmDialogClose}
                />  
            {
            (selectedLoc && dialogFormProps.isShowing) &&
                <DialoagForm
                    initialValues={
                    {...initialValues}
                    }
                    config={dialogConfig}
                    location={selectedLoc}
                    title={dialogFormProps.title}
                    dialogType={dialogFormProps.type}
                    open={dialogFormProps.isShowing}
                    onClose={(close, update)=>{
                        console.log("onclose_update", close, update);
                        if(close){
                            setDialogFormProps({ ...dialogFormProps, isShowing:false });
                        }
                        if(update !== ''){
                            if(update === 'loc_clients'){
                                getValetLocs();
                            } else {
                                getData({ location:selectedLoc.id, client:selectedClient.id });
                            }
                        }
                    }}
                />
            }
            <SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar({ open: false, msg: "" });
                }}
                message={snackBar.msg}
                type={snackBar.type}
            />
        </div>
        }</>
    )
    
}
export default ClientSubscription;
